import {
  AssemblyDto,
  CuttingPlanDto,
  IssueDto,
  MaterialHeaderDto,
  MoneyDto,
  ProjectDto,
  QuantityDto,
  RoutingHeader,
  RowDto,
  WorkingStepDto,
} from 'services/APIs/InternalAPI/internal-api.contracts'
import {
  AssemblyHeaderPointer,
  AssemblyInstancePointer,
  BomItemPointer,
  MaterialHeaderPointer,
  PartInstancePointer,
  PartTypePointer,
  ProjectPointer,
  RoutingHeaderPointer,
} from './BomItemPointer'

export enum BomItemType {
  project = 'project',
  assemblyType = 'assemblyType',
  assemblyInstance = 'assemblyInstance',
  partType = 'partType',
  partInstance = 'partInstance',
  routingHeader = 'routingHeader',
  materialHeader = 'materialHeader',
}

export enum RunnableTaskStatus {
  NotApplicable = 'NotApplicable',
  Requested = 'Requested',
  Running = 'Running',
  Done = 'Done',
  Failed = 'Failed',
}

//TODO: Temporary type for ArticleFinancialDto until be able to compile the backend
export type ArticleFinancialDto = {
  explanation: string
  totalPieces: number
  totalQuantity: QuantityDto
  costPricePerItem: MoneyDto
  costPriceOfItems: MoneyDto
  usedRatioPerPiece: QuantityDto
  totalWeight: QuantityDto
  discountRatio: QuantityDto
  surchargeRatio: QuantityDto
  salesPricePerItem: MoneyDto
  salesPriceOfItems: MoneyDto
  discountValuePerItem: MoneyDto
  discountValueOfItems: MoneyDto
  surchargeValuePerItem: MoneyDto
  surchargeValueOfItems: MoneyDto
}

//TODO: Temporary type for BomItemActivityArticleDto until be able to compile the backend
// export type BoMItemActivityArticleDto = {
//   id: string
//   description: string
//   tokens: Record<string, Array<KeywordDto>>
//   financial: ArticleFinancialDto
// }

// //TODO: Temporary type for BomItemActivityDto until be able to compile the backend
// export type BoMItemActivityDto = {
//   id?: string
//   primaryWorkingStep?: WorkingStepType
//   secondaryWorkingStep?: WorkingStepType
//   resource?: SelectedResourceDto
//   isPrimaryWorkingStep?: boolean
//   usesRawMaterial?: boolean
//   notRemovable?: boolean
//   keywords?: Record<string, Array<KeywordDto>>
//   supportsArticle?: boolean
//   article?: BoMItemActivityArticleDto
// }

interface Row {
  id: string
  type: BomItemType
  operationPending?: boolean
  validationHighlight?: boolean
  parentBomItemPointer?: BomItemPointer
  manufacturableStatus?: RunnableTaskStatus | string
  exportedToErpStatus?: RunnableTaskStatus | string
  issues?: IssueDto[] | undefined
  isLocked?: boolean
  isRawMaterialProvided?: boolean
}

export interface ProjectRow extends Row, Omit<ProjectDto, 'id'> {
  type: BomItemType.project
  costPriceExplanation?: string
  isOpen?: boolean
}

export interface PartTypeRow
  extends Row,
    Omit<RowDto, 'id' | 'isRawMaterialProvided'> {
  materialHeaderPointer?: MaterialHeaderPointer
  routingHeaderPointer?: RoutingHeaderPointer
  type: BomItemType.partType
}

export interface PartInstanceRow
  extends Row,
    Omit<RowDto, 'id' | 'isRawMaterialProvided'> {
  type: BomItemType.partInstance
}

export interface Header {
  id: string
  type: BomItemType
  isOpen?: boolean
  filteredPartTypePointers?: PartTypePointer[]
  filteredSubAssembliesPointers?: AssemblyInstancePointer[]
  filteredPartInstancePointers?: PartInstancePointer[]
}

export interface AssemblyHeaderRow
  extends Row,
    Header,
    Omit<AssemblyDto, 'id'> {
  type: BomItemType.assemblyType
  partTypePointers: PartTypePointer[]
  subAssembliesPointers?: AssemblyInstancePointer[]
  partInstancePointers?: PartInstancePointer[]
  parentBomItemPointer?:
    | AssemblyInstancePointer
    | AssemblyHeaderPointer
    | ProjectPointer
  //TODO: Temporary typing BomItemActivityDto until be able to compile the backend
  // partTypeActivities?: Array<BoMItemActivityDto>
  // assemblyActivities?: Array<BoMItemActivityDto>
}

export interface AssemblyInstanceRow
  extends Row,
    Header,
    Omit<Omit<AssemblyDto, 'id'>, 'partTypeIds'> {
  type: BomItemType.assemblyInstance
  isOpen?: boolean
  parentBomItemPointer?:
    | AssemblyInstancePointer
    | AssemblyHeaderPointer
    | ProjectPointer
  partInstancePointers?: PartInstancePointer[]
  subAssembliesPointers?: AssemblyInstancePointer[]
  workingSteps?: WorkingStepDto[]
  originalFileName?: string
  isPurchasingBomItem?: boolean
  lengthUnitAbbreviation: string
}

export interface CuttingPlanRow extends CuttingPlanDto {
  operationPending?: boolean
}

export interface MaterialHeaderRow
  extends Row,
    Header,
    Omit<MaterialHeaderDto, 'id' | 'isRawMaterialProvided'> {
  type: BomItemType.materialHeader
  partTypePointers: PartTypePointer[]
  cuttingPlans?: CuttingPlanRow[]
}

export interface RoutingHeaderRow
  extends Row,
    Header,
    Omit<RoutingHeader, 'key'> {
  type: BomItemType.routingHeader
  partTypePointers: PartTypePointer[]
}

export type BoMItemRow =
  | ProjectRow
  | PartTypeRow
  | PartInstanceRow
  | AssemblyHeaderRow
  | AssemblyInstanceRow
  | MaterialHeaderRow
  | AssemblyInstanceRow
  | RoutingHeaderRow
