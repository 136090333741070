import { ViewInArOutlined } from '@mui/icons-material'
import { LocalizedTypography } from 'components/Localization/LocalizedTypography'
import { useClientStorage } from 'customHooks/useClientStorage'
import { projectSelectors } from 'features/BillOfMaterials/store/selectors/projectSelectors'
import { useBomItemLengthUnit } from 'features/measurementSystem/utils/useBomItemUnitOfMeasure'
import { isEqual } from 'lodash'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import { useEffect, useState } from 'react'
import { useAppSelector } from 'store/configureStore'
import { ModelController } from '../manager/ModelController'
import { ToolButton } from './ToolButton'

export const BoundingBoxTool = (props: {
  bomItemPointer: BomItemPointer
  controller: ModelController
}) => {
  const dimensions = useAppSelector(
    projectSelectors.bomItemDimensionsSelector(props.bomItemPointer),
    isEqual
  )

  const bomItemLengthUnit = useBomItemLengthUnit(props.bomItemPointer)

  const [active, setActive] = useClientStorage('boundingBoxToolActive', true)

  const [modelLoaded, setModelLoaded] = useState(false)

  useEffect(() => {
    if (dimensions.boundingBox3dPoints?.length) {
      props.controller.onFileLoaded(async () => {
        setModelLoaded(true)
        if (active) {
          await props.controller.AddBoundingBox(
            dimensions.boundingBox3dPoints,
            bomItemLengthUnit
          )
          props.controller.FitAll()
        }
      })
    }
  }, [
    active,
    bomItemLengthUnit,
    dimensions.boundingBox3dPoints,
    props.controller,
  ])

  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation()

    if (active) {
      props.controller.ClearBoundingBox()
      setActive(false)
    } else {
      setActive(true)
    }
  }

  if (!dimensions.boundingBox3dPoints?.length) {
    return null
  }

  return (
    <ToolButton onClick={handleClick} active={active} disabled={!modelLoaded}>
      <ViewInArOutlined />
      <LocalizedTypography
        translationKey="project:bounding-box"
        variant="body2"
        component="p"
      >
        bounding box
      </LocalizedTypography>
    </ToolButton>
  )
}
