/* eslint-disable @typescript-eslint/no-unused-vars */
import cadex from '@cadexchanger/web-toolkit'

export class SelectedPointsCollector extends cadex.ModelPrs_SelectedEntityVisitor {
  points: cadex.ModelData_Point[]

  constructor() {
    super()
    /** @type {Array<cadex.ModelData_Point>} */
    this.points = []
  }
  /**
   * @override
   * @param {cadex.ModelPrs_SelectedPolyShapeEntity} _thePolyShapeEntity
   */
  // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
  visitPolyShapeEntity(_thePolyShapeEntity) {}
  /**
   * @override
   * @param {cadex.ModelPrs_SelectedPolyVertexEntity} thePolyVertexEntity
   */
  visitPolyVertexEntity(thePolyVertexEntity) {
    this.points.push(
      /** @type {cadex.ModelData_Point} */ thePolyVertexEntity.polyShape.coordinate(
        thePolyVertexEntity.vertexIndex
      )
    )
  }
  /**
   * @override
   * @param {cadex.ModelPrs_SelectedShapeEntity} theShapeEntity
   */
  visitShapeEntity(theShapeEntity) {
    this.points.push(
      /** @type {cadex.ModelData_Vertex} */ theShapeEntity.shape.point.clone()
    )
  }

  visitPolyFaceEntity(
    thePolyFaceEntity: cadex.ModelPrs_SelectedPolyFaceEntity
  ): void {}
  visitPolyLineEntity(
    thePolyLineEntity: cadex.ModelPrs_SelectedPolyLineEntity
  ): void {}
}
