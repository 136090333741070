import { useTranslation } from 'react-i18next'
import { QuantityDtoType } from 'services/APIs/InternalAPI/internal-api.contracts'
import { useAppSelector } from 'store/configureStore'

export type MassUnitType = MeasurementUnit & { type: 'mass' }
export type LengthUnitType = MeasurementUnit & { type: 'length' }
export type AngleUnitType = MeasurementUnit & { type: 'angle' }
export type AreaUnitType = MeasurementUnit & { type: 'area' }
export type VolumeUnitType = MeasurementUnit & { type: 'volume' }
export type DurationUnitType = MeasurementUnit & { type: 'duration' }
export type RatioUnitType = MeasurementUnit & { type: 'ratio' }
export type PiecesUnitType = MeasurementUnit & { type: 'pieces' }
export type UndefinedUnitType = MeasurementUnit & { type: 'undefined' }

export type MeasurementUnit = {
  name: string
  abbreviation: string
  decimalPrecision: number
}

export const massUnits: MassUnitType[] = [
  { type: 'mass', name: 'kilogram', abbreviation: 'kg', decimalPrecision: 2 },
  { type: 'mass', name: 'gram', abbreviation: 'g', decimalPrecision: 2 },
  { type: 'mass', name: 'pound', abbreviation: 'lb', decimalPrecision: 4 },
]

export const lengthUnits: LengthUnitType[] = [
  {
    type: 'length',
    name: 'millimeter',
    abbreviation: 'mm',
    decimalPrecision: 2,
  },
  { type: 'length', name: 'meter', abbreviation: 'm', decimalPrecision: 2 },
  { type: 'length', name: 'inch', abbreviation: 'in', decimalPrecision: 4 },
  { type: 'length', name: 'foot', abbreviation: 'ft', decimalPrecision: 4 },
]

export const thicknessUnits: LengthUnitType[] = lengthUnits.filter(
  (unit) => unit.abbreviation === 'mm' || unit.abbreviation === 'in'
)

const angleUnits: AngleUnitType[] = [
  { type: 'angle', name: 'degree', abbreviation: '°', decimalPrecision: 4 },
]

export const areaUnits: AreaUnitType[] = [
  {
    type: 'area',
    name: 'squareMeter',
    abbreviation: 'm²',
    decimalPrecision: 2,
  },
  {
    type: 'area',
    name: 'squareMillimeter',
    abbreviation: 'mm²',
    decimalPrecision: 2,
  },
  {
    type: 'area',
    name: 'squareInch',
    abbreviation: 'in²',
    decimalPrecision: 4,
  },
  {
    type: 'area',
    name: 'squareFoot',
    abbreviation: 'ft²',
    decimalPrecision: 4,
  },
]

export const volumeUnits: VolumeUnitType[] = [
  {
    type: 'volume',
    name: 'cubicMillimeter',
    abbreviation: 'mm³',
    decimalPrecision: 2,
  },
  {
    type: 'volume',
    name: 'cubicMeter',
    abbreviation: 'm³',
    decimalPrecision: 2,
  },
  {
    type: 'volume',
    name: 'cubicInch',
    abbreviation: 'in³',
    decimalPrecision: 4,
  },
  {
    type: 'volume',
    name: 'cubicFoot',
    abbreviation: 'ft³',
    decimalPrecision: 4,
  },
  { type: 'volume', name: 'liter', abbreviation: 'l', decimalPrecision: 2 },
  {
    type: 'volume',
    name: 'milliliter',
    abbreviation: 'ml',
    decimalPrecision: 2,
  },
]

const timeUnits: DurationUnitType[] = [
  {
    type: 'duration',
    name: 'minute',
    abbreviation: 'min',
    decimalPrecision: 0,
  },
  { type: 'duration', name: 'hour', abbreviation: 'hrs', decimalPrecision: 2 },
  {
    type: 'duration',
    name: 'second',
    abbreviation: 'sec',
    decimalPrecision: 0,
  },
]

const allUnits = [
  ...massUnits,
  ...lengthUnits,
  ...angleUnits,
  ...areaUnits,
  ...volumeUnits,
  ...timeUnits,
]

const ratioUnits: RatioUnitType[] = [
  { type: 'ratio', name: 'percentage', abbreviation: '%', decimalPrecision: 2 },
]

const pieceUnits: PiecesUnitType[] = [
  {
    type: 'pieces',
    name: 'piece',
    abbreviation: 'piece(s)',
    decimalPrecision: 0,
  },
]

export const useSelectableAbbreviations = (
  unitType: QuantityDtoType | string
) => {
  if (!unitType) {
    return []
  }

  if (typeof unitType === 'string') {
    // might come from the API as length, but needs to be Length (captalized)
    const captalizedUnitType =
      unitType.charAt(0).toUpperCase() + unitType.slice(1).toLowerCase()

    const type =
      QuantityDtoType[captalizedUnitType as keyof typeof QuantityDtoType]

    return getSelectableAbbreviations(type)
  }

  return getSelectableAbbreviations(unitType)
}

export const getDefaultUnit = (unitType: QuantityDtoType) => {
  return getSelectableAbbreviations(unitType)[0]
}

export const usePartyDefaultLengthUnit = () => {
  const unit = useAppSelector((state) => {
    switch (state.user.organizationContext.defaultLengthAbbreviation) {
      case 'mm':
        return lengthUnits.find((unit) => unit.abbreviation === 'mm')
      case 'cm':
        return lengthUnits.find((unit) => unit.abbreviation === 'cm')
      case 'm':
        return lengthUnits.find((unit) => unit.abbreviation === 'm')
      case 'in':
        return lengthUnits.find((unit) => unit.abbreviation === 'in')
      case 'ft':
        return lengthUnits.find((unit) => unit.abbreviation === 'ft')
      default:
        return lengthUnits[0]
    }
  })

  return unit
}

export const getMeasurementUnit = <T>(unitAbbreviation: string) => {
  return <T>allUnits.find((unit) => unit.abbreviation === unitAbbreviation)
}

export const getUnitDecimalPrecision = (
  unitAbbreviation: string,
  defaultDecimalPrecision: number = 2
) => {
  if (!unitAbbreviation) {
    return defaultDecimalPrecision
  }
  const unit = allUnits.find((unit) => unit.abbreviation === unitAbbreviation)
  return unit?.decimalPrecision ?? 2
}

export const useUnitOfMeasureTranslation = () => {
  const { t } = useTranslation('common')

  return (unit: MeasurementUnit) =>
    t(`common:units.${unit.abbreviation}`, unit.name)
}

export const getSelectableAbbreviations = (unitType: QuantityDtoType) => {
  switch (unitType) {
    case QuantityDtoType.Mass:
      return massUnits.map((unit) => unit.abbreviation)
    case QuantityDtoType.Length:
      return lengthUnits.map((unit) => unit.abbreviation)
    case QuantityDtoType.Angle:
      return angleUnits.map((unit) => unit.abbreviation)
    case QuantityDtoType.Area:
      return areaUnits.map((unit) => unit.abbreviation)
    case QuantityDtoType.Volume:
      return volumeUnits.map((unit) => unit.abbreviation)
    case QuantityDtoType.Duration:
      return timeUnits.map((unit) => unit.abbreviation)
    case QuantityDtoType.Ratio:
      return ratioUnits.map((unit) => unit.abbreviation)
    case QuantityDtoType.Pieces:
      return pieceUnits.map((unit) => unit.abbreviation)
    case QuantityDtoType.Undefined:
      return []
  }
}
