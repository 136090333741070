import { BaseController } from 'controllers/BaseController'
import { PartyRelationsController } from 'controllers/Party/Relations/PartyRelationsController'
import { ClearLoginTries } from 'features/Auth/useAppLogin'
import { Profile } from 'model/User'
import {
  PartySummaryDto,
  SendActivationEmailRequest,
} from 'services/APIs/InternalAPI/internal-api.contracts'
import { SegmentService, TelemetryService } from 'services/Telemetry'
import { AuthService } from 'services/auth'
import { newUserActions } from 'store/User/newUserReducer'
import store from 'store/configureStore'
import { Debugger } from 'utils/debugger'
import { IUserAPI } from './PartyAccessController'
import { UserAPI } from './UserAPI'

export class UserController extends BaseController<IUserAPI> {
  constructor() {
    super((onRequestChange) => new UserAPI(onRequestChange))
  }

  private setLastVisitedWorkspace(partyId: string) {
    Debugger.Info('routes: setLastVisitedWorkspace', partyId)
    localStorage.setItem('rh24_lastVisitedWorkspaceId', partyId)
  }

  public getLastVisitedWorkspace() {
    try {
      return localStorage.getItem('rh24_lastVisitedWorkspaceId')
    } catch (err) {
      console.error(err)
      return null
    }
  }

  public async SaveProfile(profile: Partial<Profile>) {
    try {
      const resp = await this.api.SaveProfile(profile)
      store.dispatch(newUserActions.setUserProfile({ userProfile: resp }))

      return resp
    } catch (err) {
      this.HandleError(err)
      this.GetProfile({ disableRedirect: true })
      throw err
    }
  }

  public async GetProfile(
    options: {
      returnUrl?: string
      disableRedirect?: boolean
      onNavigate?: (returnUrl: string, currentPartyId: string | null) => void
    } = {}
  ) {
    try {
      this.onRequestChange({
        id: 'GetProfile',
        isLoading: true,
      })

      const user = await this.api.GetProfile()

      TelemetryService.getInstance().IdentifyUser(user)

      if (
        user.partiesDto?.length === 0 &&
        user.availablePortals?.length === 1 &&
        !options?.disableRedirect
      ) {
        const formattedReturnUrl = options?.returnUrl?.replace('/app', '')

        window.location.replace(
          `${user.availablePortals[0].url}#${formattedReturnUrl || '/'}`
        )
      }

      if (!options?.disableRedirect) {
        let redirect = false

        if (user.partiesDto?.length === 1) {
          await this.SelectWorkspace(user.partiesDto[0].id, user.partiesDto)
          redirect = true
        }

        const lastVisitedWorkspaceId = this.getLastVisitedWorkspace()
        if (lastVisitedWorkspaceId) {
          try {
            // console.info(
            //   'routes: lasteVisitedWorkspaceId, will select the workspace',
            //   {
            //     lastVisitedWorkspaceId,
            //   }
            // )
            await this.SelectWorkspace(lastVisitedWorkspaceId, user.partiesDto)
            redirect = true
          } catch {
            console.error(
              `User does not have access to party from localStorage ${lastVisitedWorkspaceId}`
            )

            this.setLastVisitedWorkspace('')
          }
        } else if (user.partiesDto?.length === 1) {
          await this.SelectWorkspace(user.partiesDto[0].id)
        }

        if (redirect) {
          if (options?.onNavigate) {
            const selectedWorkspace = store.getState().user.organizationContext
            // console.info('routes: GetProfile, redirecting to', {
            //   returnUrl: options.returnUrl,
            //   selectedWorkspace,
            // })
            options.onNavigate(
              options.returnUrl || '/app',
              selectedWorkspace?.id
            )
          } else {
            window.location.replace(options.returnUrl || '/app')
          }
        }
      }

      store.dispatch(
        newUserActions.setUserProfile({
          userProfile: user,
          auth0User: AuthService.GetCurrent().GetAuth0User(),
        })
      )

      return user
    } catch (ex) {
      this.HandleError(ex)
      console.error(ex)
      console.warn('login_errorHandled', window['login_error_handled'])

      if (ex.toString().includes('login')) {
        console.warn('UserController: login expired, redirecting to login page')
        ClearLoginTries()

        if (options?.onNavigate) {
          options.onNavigate('/login', null)
        } else {
          window.location.replace('/login')
        }
      } else {
        if (!options.disableRedirect) {
          if (
            ex['message']?.toLowerCase() === 'invalid user' &&
            !options.disableRedirect
          ) {
            window.location.replace('/app/error?errorType=invalidUser')
          } else {
            if (!window['login_error_handled']) {
              console.warn(
                'UserController: /user error not handled, redirecting to error page'
              )
              window.location.replace('/app/error?errorType=maintenance')
            } else {
              console.warn('UserController: /user error already handled')
              window['login_error_handled'] = false
            }
          }
        }
      }

      throw ex
    } finally {
      this.onRequestChange({
        id: 'GetProfile',
        isLoading: false,
      })
    }
  }

  /**
   * when the user is in the self-service portal, we need to get the tenant name from the backend
   * otherwise, we can use the party name to track the logged in party.
   *
   * @param party the selected party (can be a seller or a buyer party, depending on the context)
   *
   */
  private async HandleTenantName(party: PartySummaryDto): Promise<void> {
    try {
      const tenantId = store.getState().user.tenantId
      if (tenantId) {
        if (!store.getState().user.tenantName) {
          const partyRelationController = new PartyRelationsController(party.id)
          const sellerParties = await partyRelationController.GetSellerParties()
          const sellerTenant = sellerParties?.find((x) => x.id === tenantId)

          store.dispatch(newUserActions.setSellingParties(sellerParties))

          if (sellerTenant) {
            store.dispatch(
              newUserActions.setTenantName(sellerTenant?.legalName)
            )

            TelemetryService.getInstance().TrackEvent('party_logged_in', {
              sellerPartyName: sellerTenant?.legalName,
              buyerPartyName: party.name,
              tenantId: tenantId,
            })
          }
        }
      } else {
        store.dispatch(newUserActions.setTenantName(party.name))
        TelemetryService.getInstance().TrackEvent('party_logged_in', {
          sellerPartyName: party.name,
        })
      }
    } catch (err) {
      console.error('unable to set tenant name', err)
    }
  }

  public async SelectWorkspace(partyId: string, partyList?: PartySummaryDto[]) {
    try {
      const _partyList = partyList || store.getState().user.organizations

      if (!_partyList) {
        console.error('No workspace context found. Call GetProfile first.')
        throw new Error('No workspace context found. Call GetProfile first.')
      }

      const party = _partyList.find((p) => p.id === partyId)

      if (!party) {
        throw new Error(
          `User does not have access to this workspace ${partyId}`
        )
      }

      const auth0User = AuthService.GetCurrent().GetAuth0User()

      if (auth0User) {
        SegmentService.getInstance().identify(
          auth0User.sub,
          auth0User.email,
          partyId
        )

        this.HandleTenantName(party)
      }

      document.title = party.name

      const partyConfiguration = await this.GetWorkingspaceDetails(partyId)

      store.dispatch(
        newUserActions.setOrganizationContext({
          ...party,
          ...partyConfiguration,
        })
      )

      if (window.parent) {
        window.parent?.postMessage(
          {
            type: 'RH24_EMBEDDED_DOCUMENT_TITLE',
            payload: party.name,
          },
          '*'
        )
      }

      this.setLastVisitedWorkspace(partyId)

      return party
    } catch (ex) {
      throw this.HandleError(ex)
    }
  }

  public async GetWorkingspaceDetails(partyId: string) {
    try {
      return await this.api.GetWorkspaceDetails(partyId)
    } catch (err) {
      throw this.HandleError(err)
    }
  }

  public async ClearUserOrganizationContext() {
    try {
      store.dispatch(newUserActions.clearOrganizationContext())
      this.setLastVisitedWorkspace('')
    } catch (ex) {
      throw this.HandleError(ex)
    }
  }

  public async ClearLastVisitedWorkspace() {
    try {
      this.setLastVisitedWorkspace('')
    } catch (ex) {
      throw this.HandleError(ex)
    }
  }

  public async SendActivationEmail(req: SendActivationEmailRequest) {
    try {
      return await this.api.SendActivationEmail(req)
    } catch (ex) {
      throw this.HandleError(ex)
    }
  }
}
