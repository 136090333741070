import cadex from '@cadexchanger/web-toolkit'
import { Point3dDto } from 'services/APIs/InternalAPI/internal-api.contracts'
import { MeasurementsManager } from './MeasurementsManager'

export enum MeasurementMode {
  TwoPointDistance = 0,
  ThreePointAngle = 1,
}

export class BoundingBoxManager {
  private scene: cadex.ModelPrs_Scene
  private boundingBoxPoints: cadex.ModelData_Point[]
  private measurementManager: MeasurementsManager
  private boundingBoxNode: cadex.ModelPrs_SceneNode

  constructor(
    scene: cadex.ModelPrs_Scene,
    points: Point3dDto[],
    lengthUnit: cadex.Base_LengthUnit
  ) {
    this.scene = scene

    this.measurementManager = new MeasurementsManager(
      this.scene,
      12,
      lengthUnit
    )
    this.setBoundingBoxPoints(points)
  }

  private setBoundingBoxPoints(points: Point3dDto[]) {
    this.boundingBoxPoints = points.map((point) => {
      return new cadex.ModelData_Point(point.x, point.y, point.z)
    })
  }

  public async createBoundingBox(lengthUnit: cadex.Base_LengthUnit) {
    const pointsPairs = [
      [this.boundingBoxPoints[0], this.boundingBoxPoints[1]],
      [this.boundingBoxPoints[1], this.boundingBoxPoints[2]],
      [this.boundingBoxPoints[2], this.boundingBoxPoints[3]],
      [this.boundingBoxPoints[4], this.boundingBoxPoints[5]],
      [this.boundingBoxPoints[5], this.boundingBoxPoints[6]],
      [this.boundingBoxPoints[6], this.boundingBoxPoints[7]],
      [this.boundingBoxPoints[7], this.boundingBoxPoints[4]],

      // vertical lines
      [this.boundingBoxPoints[3], this.boundingBoxPoints[7]],
      [this.boundingBoxPoints[2], this.boundingBoxPoints[6]],
      [this.boundingBoxPoints[1], this.boundingBoxPoints[5]],
      [this.boundingBoxPoints[0], this.boundingBoxPoints[4]],
      [this.boundingBoxPoints[4], this.boundingBoxPoints[5]],
      [this.boundingBoxPoints[0], this.boundingBoxPoints[3]],
    ]

    this.measurementManager.clear()

    const widthPoints = pointsPairs[0]
    const heightPoints = pointsPairs[1]
    const depthPoints = pointsPairs[9]

    const widthMeasurement =
      await this.measurementManager.createDistanceMeasurement(
        widthPoints[0],
        widthPoints[1],
        lengthUnit
      )

    const heightMeasurement =
      await this.measurementManager.createDistanceMeasurement(
        heightPoints[0],
        heightPoints[1],
        lengthUnit
      )

    const depthMeasurement =
      await this.measurementManager.createDistanceMeasurement(
        depthPoints[0],
        depthPoints[1],
        lengthUnit
      )

    const higherMeasurent = [
      widthMeasurement,
      heightMeasurement,
      depthMeasurement,
    ].sort((a, b) => b.value - a.value)[0]

    let fontSize = 60

    if (higherMeasurent.value > 850) {
      fontSize = Math.max(60, higherMeasurent.value % 191)
    } else if (higherMeasurent.value > 500 || higherMeasurent.value > 20) {
      fontSize = 60
    } else if (higherMeasurent.value > 200 || higherMeasurent.value > 8) {
      fontSize = 21
    } else if (higherMeasurent.value > 100 || higherMeasurent.value > 4) {
      fontSize = 15
    }

    widthMeasurement.fontSize = fontSize
    heightMeasurement.fontSize = fontSize
    depthMeasurement.fontSize = fontSize

    await this.measurementManager.update([
      widthMeasurement,
      heightMeasurement,
      depthMeasurement,
    ])

    const polyLineSet = cadex.ModelData_PolyLineSet.create(pointsPairs)

    const graphNode =
      new cadex.ModelPrs_SceneNodeFactory().createNodeFromPolyVertexSet(
        polyLineSet
      )

    graphNode.displayMode = cadex.ModelPrs_DisplayMode.Shaded
    graphNode.selectionMode = cadex.ModelPrs_SelectionMode.Edge

    this.boundingBoxNode = graphNode

    this.scene.addRoot(this.boundingBoxNode)

    this.scene.update()
  }

  public clear() {
    this.measurementManager.clear()
    this.scene.removeRoot(this.boundingBoxNode)
    this.scene.update()
  }
}
