import { bomItemSelector } from 'features/BillOfMaterials/store/selectors/bomItemSelector'
import { BoMItemRow, BomItemType, PartTypeRow } from 'model/Project/BoMItemRow'
import { ProjectState } from 'store/Project/ProjectTypes'

export function applyErpErrorFilter(
  projectState: ProjectState,
  row: Partial<BoMItemRow>
): boolean {
  if (
    row.type === BomItemType.materialHeader ||
    row.type === BomItemType.routingHeader
  ) {
    return true
  }

  if (row.exportedToErpStatus === 'Failed') {
    return true
  }

  if (row.exportedToErpStatus === 'Done' && row.isExportedToErp === false) {
    return true
  }

  if (row.type === BomItemType.assemblyType) {
    return row.partTypePointers.some((partTypePointer) => {
      const partType = bomItemSelector(
        { project: projectState },
        partTypePointer
      ) as PartTypeRow

      return applyErpErrorFilter(projectState, partType)
    })
  }

  // should not show this row since it's not exported to ERP yet
  return false
}
