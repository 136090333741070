import { QuantityString } from 'model/Quantity'
import { QuantityDtoType } from 'services/APIs/InternalAPI/internal-api.contracts'
import { RootAppState } from 'store/configureStore'

export const projectSurchargeRatioSelector = (state: RootAppState) => {
  if (state.project.activeProject?.financial?.surchargeRatio == null) {
    return null
  }

  return QuantityString({
    value: state.project.activeProject?.financial.surchargeRatio.value,
    unit: '%',
    quantityType: QuantityDtoType.Ratio,
  })
}
