import { bomItemSelector } from 'features/BillOfMaterials/store/selectors/bomItemSelector'
import { isEqual } from 'lodash'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import { BomItemType } from 'model/Project/BoMItemRow'
import { AppState } from 'store'
import { useAppSelector } from 'store/configureStore'
import {
  getMeasurementUnit,
  LengthUnitType,
  usePartyDefaultLengthUnit,
} from './selectableAbbreviations'

export const useBomItemLengthUnit = (bomItemPointer: BomItemPointer) => {
  const partyDefaultLength = usePartyDefaultLengthUnit()

  const partDefaultLength = useAppSelector((state: AppState) => {
    const bomItem = bomItemSelector(state, bomItemPointer)

    if (!bomItem) {
      return partyDefaultLength
    }

    switch (bomItem.type) {
      case BomItemType.assemblyType:
      case BomItemType.assemblyInstance:
      case BomItemType.partType:
      case BomItemType.partInstance:
        return getMeasurementUnit<LengthUnitType>(
          bomItem.lengthUnitAbbreviation
        )
      default:
        return partyDefaultLength
    }
  }, isEqual)

  return partDefaultLength
}
