import { BomItemType } from 'model/Project/BoMItemRow'
import { DimensionSummaryDto } from 'services/APIs/InternalAPI/internal-api.contracts'
import { RootAppState } from 'store/configureStore'
import { BomItemPointer } from '../../../../model/Project/BomItemPointer'
import { bomItemSelector } from './bomItemSelector'

export const bomItemDimensionsSelector =
  (bomItemPointer: BomItemPointer) =>
  (state: RootAppState): DimensionSummaryDto | null => {
    const bomItem = bomItemSelector(state, bomItemPointer)

    switch (bomItem?.type) {
      case BomItemType.partType:
      case BomItemType.partInstance:
      case BomItemType.assemblyType:
      case BomItemType.assemblyInstance:
        return bomItem.dimensionsSummary
    }

    return null
  }


