import { User } from '@auth0/auth0-react'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Profile } from 'model/User'
import {
  PartySummaryDto,
  QuantityDto,
  SellingPartyDto,
  UserDto,
} from 'services/APIs/InternalAPI/internal-api.contracts'
import { UserState } from './userTypes'

const initialState: UserState = {
  profile: undefined,
  organizations: undefined,
  organizationContext: undefined,
  returnUrl: undefined,
  defaultParty: undefined,
  availablePortals: undefined,
  tenantId: undefined,
}

const userSlice = createSlice({
  name: 'newUser',
  initialState: initialState,
  reducers: {
    setUserProfile: (
      state,
      action: PayloadAction<{ userProfile: UserDto; auth0User?: User }>
    ) => {
      state.profile = {
        id:
          (action.payload.userProfile && action.payload.userProfile['id']) ||
          action.payload.auth0User?.sub ||
          state.profile?.id,
        auth0Id: action.payload.auth0User?.sub || state.profile?.id,
        email:
          action.payload.userProfile?.email ||
          action.payload.auth0User?.email ||
          state.profile?.email,
        firstName:
          action.payload.userProfile?.firstName ||
          action.payload.auth0User?.given_name ||
          state.profile?.firstName,
        lastName:
          action.payload.userProfile?.lastName ||
          action.payload.auth0User?.family_name ||
          state.profile?.lastName,
        company: action.payload.userProfile?.company,
        picture:
          action.payload.userProfile?.picture ||
          action.payload.auth0User?.picture ||
          state.profile?.picture,
        intercomHash: action.payload.userProfile?.intercomHash,
      } as Profile

      state.organizations = action.payload.userProfile.partiesDto
      state.availablePortals = action.payload.userProfile.availablePortals
    },
    setOrganizations: (state, action: PayloadAction<PartySummaryDto[]>) => {
      state.organizations = action.payload
    },
    setOrganizationContext: (
      state,
      action: PayloadAction<PartySummaryDto & { defaultVatRatio?: QuantityDto }>
    ) => {
      state.organizationContext = {
        ...state.organizationContext,
        ...action.payload,
      }
    },
    setDefaultVatRatio: (state, action: PayloadAction<QuantityDto>) => {
      if (state.organizationContext) {
        state.organizationContext.defaultVatRatio = action.payload
      }
    },
    setDateTimeFormat: (state, action: PayloadAction<string>) => {
      if (state.organizationContext) {
        state.organizationContext.dateFormat = action.payload
      }
    },
    setPartyLegalName: (state, action: PayloadAction<string>) => {
      if (state.organizationContext.name !== action.payload) {
        state.organizationContext.name = action.payload
      }
    },
    setSellingParties: (state, action: PayloadAction<SellingPartyDto[]>) => {
      state.organizationContext.sellingParties = action.payload
    },
    setOrganizationContextById: (state, action: PayloadAction<string>) => {
      state.organizationContext = state.organizations?.find(
        (org) => org.id === action.payload
      )
    },
    clearOrganizationContext: (state) => {
      state.organizationContext = undefined

      localStorage.setItem('rh24_lastVisitedWorkspaceId', undefined)
    },
    setTenantId: (state, action: PayloadAction<string>) => {
      state.tenantId = action.payload
    },
    setParentPageInfo: (
      state,
      action: PayloadAction<{
        parentTitle: string
        parentHref: string
      }>
    ) => {
      state.parentPageTitle = action.payload.parentTitle
      state.parentPageHref = action.payload.parentHref
    },
    setTenantName: (state, action: PayloadAction<string>) => {
      state.tenantName = action.payload
    },
    setDefaultLengthUnit: (state, action: PayloadAction<string>) => {
      state.organizationContext.defaultLengthAbbreviation = action.payload
    },
  },
})

export default userSlice.reducer
export const newUserActions = userSlice.actions
