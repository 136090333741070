import {
  getUnitDecimalPrecision,
  usePartyDefaultLengthUnit,
  useSelectableAbbreviations,
} from 'features/measurementSystem/utils/selectableAbbreviations'
import i18next from 'i18next'
import {
  QuantityDto,
  QuantityDtoType,
} from '../services/APIs/InternalAPI/internal-api.contracts'

export function QuantityParser(
  str: string,
  quantityDtoType?: QuantityDtoType
): QuantityDto {
  const parsed = str.match(/(-?\d*\.?\,?\d+)\s?([a-z%]+)/)
  return {
    value: parseFloat(parsed[1]),
    unit: parsed[2] ?? '',
    quantityType: quantityDtoType,
  }
}

export function QuantityIsValid(quantity: QuantityDto) {
  if (!quantity) return false

  return quantity.unit && quantity.value >= 0
}

export function isQuantityEqual(q1: QuantityDto, q2: QuantityDto) {
  return q1.value === q2.value && q1.unit === q2.unit
}

export function QuantitySorter(q1: QuantityDto, q2: QuantityDto) {
  if (q1.value > q2.value) return 1
  if (q1.value < q2.value) return -1
  return 0
}

export const formatFn = (value: number, padStart = 2) =>
  value.toString().padStart(padStart, '0')

export type QuantityStringOptions = {
  showValue1?: boolean
  showUnit?: boolean
  maxDecimalFractions?: number
  minDecimalFractions?: number
  showPlusSign?: boolean
}

export function QuantityString(
  quantity: QuantityDto,
  options: QuantityStringOptions = {
    showValue1: true,
    showUnit: true,
    minDecimalFractions: 0,
    maxDecimalFractions: undefined,
    showPlusSign: false,
  }
) {
  if (!quantity || isNaN(quantity.value)) return ''

  const maxDecimalFractions =
    options?.maxDecimalFractions ?? getUnitDecimalPrecision(quantity.unit, 2)

  let minDecimalFractions = 0

  if (!options.minDecimalFractions) {
    // if a options.minDecimalFractions is not provided, check if its a whole number or a fraction. If a fraction, set minDecimalFractions to 2
    if ((quantity.value * 100) % 10 !== 0) {
      minDecimalFractions = 2
    } else {
      minDecimalFractions = maxDecimalFractions
    }
  }

  const value = new Intl.NumberFormat(i18next.language, {
    maximumFractionDigits:
      maxDecimalFractions === 0 ? undefined : maxDecimalFractions,
    minimumFractionDigits: minDecimalFractions,
  }).format(quantity.value)

  if (options.showUnit === false) return value

  let localizedUnitTKey = ''

  if (quantity.unit) {
    localizedUnitTKey = `units.${quantity.unit}`

    if (quantity.value <= 1 && quantity.value >= 0) {
      localizedUnitTKey = `common:units-${quantity.unit}_one`
    } else {
      localizedUnitTKey = `common:units-${quantity.unit}_other`
    }
  }

  const localizedUnitString = localizedUnitTKey
    ? i18next.t(localizedUnitTKey, { defaultValue: quantity.unit })
    : ''

  if (!options.showValue1 && quantity.value === 1) return localizedUnitString

  return `${
    options.showPlusSign && quantity.value > 0 ? '+' : ''
  }${value} ${localizedUnitString}`?.trim()
}

export function RoundedQuantityString(quantity: QuantityDto) {
  if (!quantity) return ''

  const roundedQuantity = { ...quantity }
  roundedQuantity.value = parseInt(
    parseFloat(quantity.value?.toString() || '0')?.toFixed(0)
  )
  return QuantityString(roundedQuantity)
  // return `${numeral(quantity.value).format('0,0')} ${quantity.unit}`
}

export function useDefaultQuantity(
  quantityType: QuantityDtoType,
  defaultUnit?: string
) {
  const defaultLengthUnit = usePartyDefaultLengthUnit()
  const selectableAbbreviations = useSelectableAbbreviations(quantityType)

  if (quantityType === QuantityDtoType.Length) {
    return {
      value: 0,
      unit: defaultLengthUnit.abbreviation,
      quantityType,
    }
  }

  return {
    value: 0,
    unit: defaultUnit ?? selectableAbbreviations[0],
    quantityType,
  }
}
