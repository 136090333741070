import { MenuItem, Select, SelectChangeEvent } from '@mui/material'
import {
  getMeasurementUnit,
  lengthUnits,
  LengthUnitType,
  MeasurementUnit,
  useUnitOfMeasureTranslation,
} from 'features/measurementSystem/utils/selectableAbbreviations'
import { useBomItemLengthUnit } from 'features/measurementSystem/utils/useBomItemUnitOfMeasure'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import { useEffect, useState } from 'react'
import { ModelController } from '../manager/ModelController'

export const UnitSelectorTool = (props: {
  controller: ModelController
  bomItemPointer: BomItemPointer
}) => {
  const bomItemLengthUnit = useBomItemLengthUnit(props.bomItemPointer)

  useEffect(() => {
    props.controller.onFileLoaded(() => {
      props.controller.SetLengthUnit(bomItemLengthUnit)
    })
  }, [bomItemLengthUnit, props.controller])

  const [unit, setUnit] = useState<MeasurementUnit>(bomItemLengthUnit)

  const handleChangeUnit = (e: SelectChangeEvent) => {
    const unit = getMeasurementUnit<LengthUnitType>(e.target.value)

    if (unit) {
      setUnit(unit)
      props.controller.SetLengthUnit(unit)
    }
  }

  const translateUnit = useUnitOfMeasureTranslation()

  return (
    <>
      <Select value={unit.abbreviation} onChange={handleChangeUnit} fullWidth>
        {lengthUnits.map((unit) => {
          return (
            <MenuItem key={unit.abbreviation} value={unit.abbreviation}>
              {translateUnit(unit)}
            </MenuItem>
          )
        })}
      </Select>
    </>
  )
}
